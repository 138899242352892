import {SCAdvertise} from "./styled";

export function Advertise(){
    return(
        <>
            <SCAdvertise>
                Delivery
                <br/>
                Servimos almoço no local
                <br/>
                <span>
                    De Terça a Quinta das 10h às 17h | Sextas e Sábados das 10h as 19h
                </span>
            </SCAdvertise>
        </>
    )
}