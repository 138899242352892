import styled from 'styled-components'

export const SCLogo = styled.div`
  width: 28.125rem; //450px at 1920p
  height: 13.75rem; //220px at 1080p

  margin-top: 2rem;

  background-image: url("./assets/images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  @media (max-width:1000px){
    width: 90%;
    margin: 0;
  }
`