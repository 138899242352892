import styled from 'styled-components'

const wallList = ["A", "B", "C"]
const wall = wallList[Math.floor(Math.random() * wallList.length)]

export const SCContainer = styled.div`
  width: 100vw;
  max-width: 1920px;

  background-image: url('./assets/images/background${wall}.png');
  background-position: center right;
  background-repeat: no-repeat;

  display: flex;

  @media (max-width: 1000px) {
    background-image: none;
  }

  .over {
    width: 65vw;
    max-width: 1250px;
    min-width: 1000px;

    background-color: linear-gradient(var(--background), var(--background), #FFF0, #FFF0);
    background-image: url('./assets/images/contentArea.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;

    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
      background-image: none;
      min-width: unset;
      width: 100vw;
    }
    
    .content {
      height: 100vh;
      min-height: fit-content;
      max-height: 1080px;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 2rem 20.62rem 2rem 0;
      gap: 1.5rem;
      
      @media (max-width: 1000px){
        padding: 2rem 0;
      }
    }
  }
`