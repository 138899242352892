import {GlobalStyle} from './style/global'
import {Home} from "./pages/Home";

function App() {
    return (
        <>
            <GlobalStyle/>
            <Home/>
        </>
    );
}

export default App;
