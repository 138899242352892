import {SCNumbers} from "./styled";
import {FaWhatsapp, FaPhoneAlt} from "react-icons/fa";

export function ContactNumbers() {
    return (
        <>
            <SCNumbers>
                <div className="whatsapp">
                    <a href="https://wa.me/5513981118310">
                        <FaWhatsapp/>(13)98111.8310
                    </a>
                </div>
                <div className="phone">
                    <a href="tel:1332252540">
                        <FaPhoneAlt/>(13)3225.2540
                    </a>
                </div>
            </SCNumbers>
        </>
    )
}