import {SCContainer} from './styled'
import {SociaL} from "../../components/Social";
import {Logo} from "../../components/Logo";
import {Dishes} from "../../components/Dishes";
import {Advertise} from "../../components/Advertise";
import {Address} from "../../components/Address";
import {ContactNumbers} from "../../components/ContactNumbers";


export function Home(){

    return(
        <>
            <SCContainer>
                <div className="over">
                    <div className="content">
                        <SociaL visible="desk"/>
                        <Logo/>
                        <SociaL visible="mobile"/>
                        <Dishes/>
                        <Advertise/>
                        <Address/>
                        <ContactNumbers/>
                    </div>
                </div>
            </SCContainer>
        </>
    )
}