import {createGlobalStyle} from "styled-components"

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #EFEFEF;
    --green-500: #517C57;
    --red-500: #A72E1C;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%
    }
    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body, input, textarea, button {
    font-family: sans-serif;
  }

  body {
    background-color: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`