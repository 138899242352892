import {SCAddress} from "./styled";

export function Address(){
    return(
        <>
            <SCAddress>
                Avenida Moura Ribeiro, 48, Santos/SP
            </SCAddress>
        </>
    )
}