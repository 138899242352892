import {SCDishes} from "./styled";

export function Dishes(){
    return(
        <>
            <SCDishes>
                <ul>
                    <li>Massas italianas com diversos recheios</li>
                    <li>Tortas</li>
                    <li>Pratos variados para o seu almoço</li>
                    <li>Bolos</li>
                    <li>Opções veganas</li>
                    <li>Opções de carnes brancas e vermelhas</li>
                    <li>Doces italianos</li>
                    <li>Sobremesas</li>
                </ul>
            </SCDishes>
        </>
    )
}