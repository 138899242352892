import styled from "styled-components";

export const SCAdvertise = styled.div`
  font-size: 2rem;

  span {
    display: block;
    font-size: 1rem;
  }
  
  @media (max-width:1000px){
    text-align: center;
  }
`