import {SCSocialBar} from "./styled";
import {FaFacebookF, FaInstagram, FaWhatsapp} from 'react-icons/fa'


export function SociaL(props) {
    const device = props.visible
    return (
        <>
            <SCSocialBar className={device}>
                <a href="https://www.facebook.com/massasmarape">
                    <FaFacebookF/>
                </a>
                <a href="https://www.instagram.com/massasmarape">
                    <FaInstagram/>
                </a>
                <a href="https://wa.me/5513981118310">
                    <FaWhatsapp/>
                </a>
            </SCSocialBar>
        </>
    )
}