import styled from "styled-components";

export const SCSocialBar = styled.div`
  margin-left: auto;
  margin-right: 100px;

  display: flex;
  gap: 1rem;

  font-size: 3rem;
  color: #222;
  
  &.mobile{
    margin: 0;
    gap: 2rem;
    color: var(--green-500);
    @media (min-width:1001px){
      display: none;
    }
  }
  
  &.desk{
    @media (max-width: 1000px){
      display: none;
    }
  }
`