import styled from "styled-components";

export const SCNumbers = styled.div`
  width: 100%;

  margin-top: 2rem;

  font-size: 1.5rem;
  display: flex;
  flex-direction: column;

  color: var(--green-500);

  div {
    display: flex;
    align-items: center;

    padding: 1rem 1rem 1rem 8rem;
    line-height: 2.5rem;
    
    a{
      display: flex;
      align-items: center;
    }
    @media (max-width:1000px){
      width: 100vw !important;
      height: 5rem;
      
      border-radius: unset !important;
      justify-content: center;
      padding: 0;
    }

    svg {
      margin: 0 .5rem 0 0;
      font-size: 2.5rem;
    }
  }

  .whatsapp {
    width: fit-content;
    border-radius: 0 3rem 3rem 0;

    background-color: var(--green-500);

    display: flex;
    color: var(--background);
  }
`