import styled from "styled-components";

export const SCDishes = styled.div`
  padding: 0 5px;
  ul {
    max-width: 700px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    font-size: 1.4rem;
    text-align: center;

    list-style: none;

    @media(max-width: 1000px){
      opacity: .8;
    }

    li {
      &::before {
        content: "•";
          
        padding: 0 .4rem;
        color: var(--red-500);
      }
    }
  }
`